<template>
  <div :class="wrapper">
    <template v-if="childDataLoaded">
      <hero
        :heroImgSrc="cloudinary.ENTERPRISE_ILLUSTRATION"
        :heroTitle="$t('enterpriseTitle')"
        :heroDescrip="$t('enterpriseTitleDes')"
      />
      <solution-card
        :titleText="$t('educationPassport')"
        v-bind:subDetails="$t('enterprisePassportDes')"
        v-bind:imageUrl="cloudinary.ENTERPRISE_DAILY_PASSPORT"
        imageAlt=""
        v-bind:isTextFirst="false"
      />

      <solution-card
        :titleText="$t('educationTest')"
        v-bind:subDetails="$t('educationTestDes')"
        v-bind:imageUrl="cloudinary.EDUCATION_TEST_ADMINISTRATION"
        imageAlt=""
        v-bind:isTextFirst="true"
      />

      <solution-card
        :titleText="$t('enterpriseCheckUp')"
        v-bind:subDetails="$t('enterpriseCheckUpDes')"
        v-bind:imageUrl="cloudinary.ENTERPRISE_EMPLOYEE_CHECKUPS"
        imageAlt=""
        v-bind:isTextFirst="false"
      />

      <solution-card
        :titleText="$t('enterpriseEducate')"
        v-bind:subDetails="$t('enterpriseEducateDes')"
        v-bind:imageUrl="cloudinary.ENTERPRISE_EDUCATE_TEAM"
        imageAlt=""
        v-bind:isTextFirst="true"
      />

      <section class="section">
        <case-management-card />
      </section>
      <section class="section">
        <ad :adData="getAdComponent" />
      </section>

      <section class="section last">
        <featured-logos />
      </section>
    </template>
    <div class="mobile">
      <AppStoreLinks />
    </div>
  </div>
</template>

<script>
import AppStoreLinks from '@/components/basic/AppStoreLinks.vue';
import Ad from '@/components/structure/Ad';
import cloudinary from '@/constants/cloudinary';
import FeaturedLogos from '@/components/basic/FeaturedLogos';
import Hero from '@/components/basic/Hero';
import SolutionCard from '@/views/SolutionCard.vue';
import CaseManagementCard from '@/views/CaseManagementCard.vue';
import { butter } from '@/buttercms';

export default {
  name: 'enterprise',
  components: {
    Ad,
    AppStoreLinks,
    FeaturedLogos,
    CaseManagementCard,
    Hero,
    SolutionCard,
  },
  data() {
    return {
      title: 'Enterprise',
      wrapper: 'enterprise_component_wrapper',
      thisPage: {},
      childDataLoaded: false,
    };
  },
  methods: {
    fetchCms() {
      const locale = this.$route.params.locale;
      butter.page
        .retrieve('*', 'enterprise', { locale: locale })
        .then(resp => {
          this.thisPage = resp.data.data;
          this.childDataLoaded = true;
        })
        .catch(function(resp) {
          console.log(resp);
        });
    },
  },
  computed: {
    getAdComponent() {
      return this.childDataLoaded ? this.thisPage.fields.ad : [];
    },
  },
  created() {
    this.fetchCms();
    this.cloudinary = cloudinary;
  },
  mounted() {
    if (this.$route.query.target) {
      const target = `#${this.$route.query.target}`;
      this.$nextTick(() => {
        document.querySelector(target).scrollIntoView();
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import '../styles/global/marketing.scss';

.section {
  @extend %section;
}

.section.last {
  @extend %section-last;
}

.ad-wrapper {
  background: #ecfafe;
  padding: 2rem;
}

.mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $white;
  box-shadow: 0 -2px 4px rgba(8, 35, 48, 0.1);
  @include bp-medium {
    display: none;
  }
  .stores {
    display: flex;
    justify-content: space-between;
    margin: 1.5rem;
  }
  @include bp-mediumsmall {
    display: none;
  }
}
</style>
